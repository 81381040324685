<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link
            to="/"
            class="d-flex align-center"
          >
            <v-img
              :src="require('@/assets/images/logos/logo.png')"
              max-height="30px"
              max-width="30px"
              alt="logo"
              contain
              class="me-3 "
            ></v-img>

            <h2 class="text-2xl font-weight-semibold">
              BAI Tracking System! 👋🏻
            </h2>
          </router-link>
        </v-card-title>


        <!-- login form -->
        <v-card-text>
          <v-form @keyup.native.enter="resetAction">

            <v-text-field
              v-model="email"
              outlined
              label="Email"
              placeholder="john@iimb.ac.in"
              :rules="emailRules"
              ref="email"
              required
            >
            </v-text-field>

            <v-text-field
              v-if="!invokeEmail"
              v-model="password"
              autocomplete="off"
              outlined
              :type="isPasswordVisible ? 'text' : 'password'"
              label="New Password"
              placeholder="············"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              hide-details
              @click:append="isPasswordVisible = !isPasswordVisible"
            ></v-text-field>

            <meter v-if="!invokeEmail" class="mt-1" :value="meterValue" max="4" id="password-strength-meter"></meter>

            <v-alert
              border="left"
              colored-border
              color="primary"
              v-if="showStrengthMessage"
            >
              {{strengthMessage}} 
            </v-alert>

            <v-btn
              :disabled="isDisabled"
              block
              color="primary"
              class="mt-6"
              @click="resetAction"
            >
              {{buttonText}}
            </v-btn>
          </v-form>
        </v-card-text>

        <!-- create new account  -->
        <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
          <span class="me-2">
            Already have an account?
          </span>
          <router-link :to="{ name:'pages-login' }">
            Sign in instead
          </router-link>
        </v-card-text>

      </v-card>
    </div>

    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="190"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
    >

    <!-- tree -->
    <v-img
      class="auth-tree"
      width="247"
      height="185"
      src="@/assets/images/misc/tree.png"
    ></v-img>

    <!-- tree  -->
    <v-img
      class="auth-tree-3"
      width="377"
      height="289"
      src="@/assets/images/misc/tree-3.png"
    ></v-img>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { reset, newPassword } from '../../api/user'

export default {
  mounted() {
 
    if (!this.$route.query)
      return

    this.token =  (this.$route.query && this.$route.query.token) ? this.$route.query.token : '';
  },
  computed: {
    invokeEmail(){
      return this.token.length < 1
    },
    buttonText(){
      return this.invokeEmail ? 'Send password reset mail' : 'Update Password'
    },
    isDisabled(){
      
      const emailValid = /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.email);

      if(!emailValid){
        return true
      }

      if(this.isProcessing){
        return true
      }

      if(this.meterValue < 3 && !this.invokeEmail){
        return true
      }

      return false
    },
    showStrengthMessage(){
      return this.strengthMessage.length > 1
    }
  },
  data() {

    return {
      token: '',
      isPasswordVisible: false,
      email: '',
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'E-mail must be valid',
      ],
      password: '',
      isProcessing: false,
      meterValue: 0,
      strengthMessage: '',
      emailErrors: '',

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
  watch: {
    password(n, o){
      const result = zxcvbn(n);

      // Update the password strength meter
      this.meterValue = result.score;
      this.strengthMessage = (n !== '') ? result.feedback.warning + " " + result.feedback.suggestions : ''
    }
  },
  methods:{
    async resetAction(){
      
      if(this.isDisabled){
        return;
      }

      try{
        this.isProcessing = true

        if (this.token.length > 2) {
          await newPassword({token: this.token, password: this.password})
          this.$toast.open({
            message:'Password Updated.',
            type:'success',
          });
        }
        else{
         await reset({email: this.email})
          this.$toast.open({
            message:'We will send you a password reset link within the next 5 minutes. Please read the instructions in the email to set-up your password.',
            type:'info',
          });
        }

        this.isProcessing = false
      }
      catch(e){
        if(e.code === 'ERR_BAD_REQUEST'){
          this.$toast.open({
            message: e.response.data.details || e.response.data.error.message,
            type: 'error',
          });
        }
        else{
          this.$toast.open({
            message: 'Unknown error. We are unable to process your request at this time. Please get in touch with the admin to get the issue resolved.',
            type: 'error',
          });
        }
        this.isProcessing = false
      }
    }
  }
}
</script>

<style lang="scss">
  @import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
  meter {
    /* Reset the default appearance */
    -webkit-appearance: none;
      -moz-appearance: none;
            appearance: none;
              
        margin: 0 auto 1em;
        width: 100%;
        height: .5em;
          
          /* Applicable only to Firefox */
        background: none;
        background-color: rgba(0,0,0,0.1);
    }

    meter::-webkit-meter-bar {
      background: none;
      background-color: rgba(0,0,0,0.1);
    }

    meter[value="1"]::-webkit-meter-optimum-value { background: red; }
    meter[value="2"]::-webkit-meter-optimum-value { background: yellow; }
    meter[value="3"]::-webkit-meter-optimum-value { background: green; }
    meter[value="4"]::-webkit-meter-optimum-value { background: green; }

    meter[value="1"]::-moz-meter-bar { background: red; }
    meter[value="2"]::-moz-meter-bar { background: yellow; }
    meter[value="3"]::-moz-meter-bar { background: green; }
    meter[value="4"]::-moz-meter-bar { background: green; }

</style>
